import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/core/services/language.service";

@Component({
  selector: "app-page-title",
  templateUrl: "./pagetitle.component.html",
  styleUrls: ["./pagetitle.component.scss"],
})
export class PagetitleComponent implements OnInit {
  @Input() breadcrumbItems;
  @Input() title: string;

  constructor(
    public languageService: LanguageService,
    public translate: TranslateService
  ) {}

  ngOnInit() {}
}
