import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class LanguageService {
  public languages: string[] = ["en", "id"];

  constructor(public translate: TranslateService) {
    let browserLang;
    this.translate.addLangs(this.languages);
    if (localStorage.getItem("lang")) {
      this.setLanguage(localStorage.getItem("lang"));
      browserLang = localStorage.getItem("lang");
    } else {
      this.setLanguage("en");
      browserLang = translate.getBrowserLang();
    }
    translate.use(browserLang.match(/en|id/) ? browserLang : "en");
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    if (localStorage.getItem("lang")) {
      localStorage.removeItem("lang");
    }
    localStorage.setItem("lang", lang);
  }
}
