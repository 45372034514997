import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  HOST = environment.appUrl;
  PATH = "";
  URL = "";
  TOKEN = "";
  constructor(private http: HttpClient) {}

  getMenu(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  getMenuParent(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus/parent";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  getMenuChild(parent_id: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus/child/" + parent_id;
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  updateMenu(dataPost: any, menu_id: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus/update/" + menu_id;
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }

  deleteMenu(menu_id: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus/delete/" + menu_id;
    this.URL = this.HOST + this.PATH;
    return this.http.delete<any>(`${this.URL}`, http_options);
  }

  createMenu(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/menus/create/";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }
}
