import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { KatakodeauthService } from "src/app/account/auth/katakodeauth.service";
import { ConflistService } from "src/app/pages/configurations/conflist/conflist.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  error: any = "";
  userAvatar: any;
  userCompleteName: any;
  siteInformation = {
    logo_small: "",
    logo_inverse: "",
    logo: "",
  };
  avatarImage: any;
  profileImage: any;
  selectedLanguage: any;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    public toastService: ToastrService,
    public kataKodeAuthService: KatakodeauthService,
    private conflistService: ConflistService,
    private spinner: NgxSpinnerService
  ) {}

  listLang: any = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    {
      text: "Indonesia",
      flag: "assets/images/flags/indonesia.png",
      lang: "id",
    },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.selectedLanguage = this.kataKodeAuthService.useLang();
    const val = this.listLang.filter((x) => x.lang === this.selectedLanguage);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    if (localStorage.getItem("userLoggedInDetail")) {
      const userLoggedInDetail = JSON.parse(
        localStorage.getItem("userLoggedInDetail")
      );
      this.userCompleteName = userLoggedInDetail.complete_name;
      this.userAvatar = environment.appUrl + userLoggedInDetail.avatar;
    }
    this.siteIdentity();
    this.showProfile();
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.languageService.setLanguage(lang);
    // this.toastService.info(this.translate.instant("SWAL.LANGUAGE.SET"), "", {
    //   timeOut: 3000,
    //   progressBar: true,
    //   positionClass: "toast-top-center",
    // });
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    Swal.fire({
      title: this.translate.instant("SWAL.NOTIFICATION.ARE_YOU_SURE"),
      text: this.translate.instant("SWAL.NOTIFICATION.LOGOUT"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: this.translate.instant("BUTTON.YES"),
      cancelButtonText: this.translate.instant("BUTTON.CANCEL"),
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.kataKodeAuthService.logout().subscribe(
          (response) => {
            if (response.success == true) {
              this.toastService.success(response.message, "", {
                timeOut: 3000,
                positionClass: "toast-top-center",
              });
              this.router.navigate(["/account/login"]);
            }
            this.spinner.hide();
          },
          (err) => {
            this.toastService.error(err.message, "", {
              timeOut: 3000,
              positionClass: "toast-top-center",
            });
            this.router.navigate(["/account/login"]);
            this.spinner.hide();
          }
        );
      }
    });
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  siteIdentity() {
    // const data = this.kataKodeAuthService.siteIdentity();

    // this.siteInformation = {
    //   logo_small: environment.appUrl + data.site_logo_small,
    //   logo_inverse: environment.appUrl + data.site_logo_inverse,
    //   logo: environment.appUrl + data.site_logo,
    // };
    this.spinner.show();
    this.conflistService.getSiteIdentity().subscribe(
      (response) => {
        if (response.success == true) {
          this.siteInformation = {
            logo_small: environment.appUrl + response.data.site_logo_small,
            logo_inverse: environment.appUrl + response.data.site_logo_inverse,
            logo: environment.appUrl + response.data.site_logo,
          };
        }
        this.spinner.hide();
      },
      (err) => {
        this.toastService.error(err, "", {
          timeOut: 3000,
          positionClass: "toast-top-center",
        });
        this.spinner.hide();
      }
    );
  }

  showProfile() {
    this.spinner.show();
    this.kataKodeAuthService.profile().subscribe(
      (response) => {
        if (response.success == true) {
          this.avatarImage =
            environment.appUrl + response.data.userLoggedInDetail.avatar;
          this.profileImage =
            environment.appUrl + response.data.userLoggedInDetail.image;
        }
        this.spinner.hide();
      },
      (err) => {
        this.toastService.error(err, "", {
          timeOut: 3000,
          positionClass: "toast-top-center",
        });
        this.spinner.hide();
      }
    );
  }
}
