import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from "src/app/pages/configurations/menu/menu.service";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  HOST = environment.appUrl;
  PATH = "";
  URL = "";
  TOKEN = "";
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    private toastService: ToastrService
  ) {}
}
