import { Component, OnInit } from "@angular/core";
import { KatakodeauthService } from "src/app/account/auth/katakodeauth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
/**
 * Footer component
 */
export class FooterComponent implements OnInit {
  // set the currenr year
  year: number = new Date().getFullYear();
  siteInformation: any;
  constructor(private kataKodeAuthService: KatakodeauthService) {}

  ngOnInit() {
    this.siteInformation = this.kataKodeAuthService.siteIdentity();
  }
}
