import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root",
})
export class ConflistService {
  HOST = environment.appUrl;
  PATH = "";
  URL = "";
  TOKEN = "";
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private toastService: ToastrService
  ) {}

  getSiteIdentity(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/site_identity";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  siteIdentityUpdate(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/site_identity_update";
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }

  siteLogoUpload(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/site_logo/upload";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }

  siteLogoSmallUpload(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/site_logo_small/upload";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }

  siteLogoInverseUpload(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/site_logo_inverse/upload";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }

  siteFaviconUpload(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/favicon/upload";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }

  showSMTP(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/smtp";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  updateSMTP(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/smtp/update";
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }

  showSendingMail(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/sending_mail";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  sendingMailRegisterUser(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/sending_mail/register_user";
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }

  showMaxSessions(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/max_sessions";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  updateMaxSessions(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/max_sessions_update";
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }

  updateWapi(dataPost: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.TOKEN}`,
      }),
    };
    this.PATH = "/configurations/wapi/update";
    this.URL = this.HOST + this.PATH;
    return this.http.put<any>(`${this.URL}`, dataPost, http_options);
  }
}
