import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { ToastrService } from "ngx-toastr";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastrService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.toastService.error("You are not authorized", "", {
            timeOut: 3000,
            positionClass: "toast-top-center",
            closeButton: true,
          });
          // location.reload();
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
