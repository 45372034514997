import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  favIcon: any = document.querySelector("#favIcon");
  ngOnInit() {
    this.favIcon.href =
      environment.appUrl + "/public/uploads/site_images/favico.png";
    const env = environment.production;
  }
}
