import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import axios from "axios";
import { ToastrService } from "ngx-toastr";
import { SidebarService } from "src/app/layouts/sidebar/sidebar.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MenuService } from "src/app/pages/configurations/menu/menu.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class KatakodeauthService {
  HOST = environment.appUrl;
  PATH = "";
  URL = "";
  TOKEN = "";
  LANGUAGE = "en";
  constructor(
    private toastService: ToastrService,
    private http: HttpClient,
    private sidebarService: SidebarService,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    public _cookiesService: CookieService
  ) {}

  useLang() {
    if (localStorage.getItem("lang")) {
      this.LANGUAGE = localStorage.getItem("lang");
    }
    return this.LANGUAGE;
  }

  async site_identity() {
    try {
      const response = await axios.get(
        environment.appUrl + "/configurations/site_identity"
      );
      return response;
    } catch (error) {
      this.toastService.error(error, "", {
        timeOut: 10000,
        progressBar: true,
        positionClass: "toast-top-center",
      });
      return error;
    }
  }

  async store_site_identity() {
    try {
      const response = await axios.get(
        environment.appUrl + "/configurations/site_identity"
      );
      if (response.data.success == true) {
        if (localStorage.getItem("site_identity")) {
          localStorage.removeItem("site_identity");
        }
        localStorage.setItem(
          "site_identity",
          JSON.stringify(response.data.data)
        );
      }
    } catch (error) {
      console.log(error);
      this.toastService.error(error, "", {
        timeOut: 10000,
        progressBar: true,
        positionClass: "toast-top-center",
      });
    }
  }

  siteIdentity() {
    return JSON.parse(localStorage.getItem("site_identity"));
  }

  async login(username: string, password: string) {
    try {
      const headers = {
        "Accept-Language": this.useLang(),
      };

      const response = await axios.post(
        environment.appUrl + "/auth/login",
        {
          username: username,
          password: password,
        },
        { headers: headers }
      );
      if (response.data.success == true) {
        const user = response.data.data.userLoggedIn.username;
        const userLoggedIn = response.data.data.userLoggedIn;
        const userLoggedInDetail = response.data.data.userLoggedInDetail;
        const roles = response.data.data.roles;
        const session = response.data.data.session;
        localStorage.setItem("currentUser", JSON.stringify(user));
        localStorage.setItem("userLoggedIn", JSON.stringify(userLoggedIn));
        localStorage.setItem("roles", JSON.stringify(roles));
        localStorage.setItem("session", JSON.stringify(session));
        localStorage.setItem(
          "userLoggedInDetail",
          JSON.stringify(userLoggedInDetail)
        );
        this.store_site_identity();
        this.showMenuSidebar();
      }
      return response;
    } catch (error) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("userLoggedIn");
      localStorage.removeItem("userLoggedInDetail");
      localStorage.removeItem("roles");
      localStorage.removeItem("session");
      localStorage.removeItem("menu");
      localStorage.removeItem("site_identity");
      const response = {
        data: { success: false, message: error.response.data.message },
      };
      return response;
    }
  }

  logout(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
        "Accept-Language": this.useLang(),
      }),
    };
    this.PATH = "/auth/logout";
    this.URL = this.HOST + this.PATH;
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userLoggedIn");
    localStorage.removeItem("userLoggedInDetail");
    localStorage.removeItem("roles");
    localStorage.removeItem("session");
    localStorage.removeItem("menu");
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  async isAuth() {
    try {
      let response = {
        success: false,
      };
      if (localStorage.getItem("session")) {
        const session = JSON.parse(localStorage.getItem("session"));
        // alert(session.token);
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${session.token}`,
            "Accept-Language": this.useLang(),
          },
        };
        response = await axios.get(environment.appUrl + "/auth", config);
      }
      return response;
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  }

  showMenuSidebar() {
    this.spinner.show();
    this.menuService.getMenu().subscribe(
      (response) => {
        if (response.success == true) {
          localStorage.setItem("menu", JSON.stringify(response.data));
        }
        this.spinner.hide();
      },
      (err) => {
        this.toastService.error(err.message, "", {
          timeOut: 3000,
          positionClass: "toast-top-center",
        });
        this.spinner.hide();
      }
    );
  }

  profile(): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
        "Accept-Language": this.useLang(),
      }),
    };
    this.PATH = "/auth";
    this.URL = this.HOST + this.PATH;
    return this.http.get<any>(`${this.URL}`, http_options);
  }

  isAuthMenu(path: any): Observable<any> {
    if (localStorage.getItem("session")) {
      const session = JSON.parse(localStorage.getItem("session"));
      this.TOKEN = session.token;
    }
    const dataPost = {
      path: path,
    };
    const http_options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.TOKEN}`,
        "Accept-Language": this.useLang(),
      }),
    };
    this.PATH = "/auth/is_menu";
    this.URL = this.HOST + this.PATH;
    return this.http.post<any>(`${this.URL}`, dataPost, http_options);
  }

  async isAuth_Menu(path: any) {
    try {
      let response = {
        success: false,
      };
      if (localStorage.getItem("session")) {
        const session = JSON.parse(localStorage.getItem("session"));
        // alert(session.token);
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${session.token}`,
            "Accept-Language": this.useLang(),
          },
        };
        const dataPost = {
          path: path,
        };
        response = await axios.post(
          environment.appUrl + "/auth/is_menu",
          dataPost,
          config
        );
      }
      return response;
    } catch (error) {
      console.log(error);
      return error.response.data.message;
    }
  }
}
