import { Component, Injectable } from "@angular/core";
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { KatakodeauthService } from "src/app/account/auth/katakodeauth.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthGuard {
  constructor(
    private router: Router,
    private kataKodeAuthService: KatakodeauthService,
    private toastService: ToastrService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isMenu = await this.kataKodeAuthService.isAuth_Menu(state.url);
    if (isMenu.data) {
      if (isMenu.data.success == true) {
        return true;
      } else {
        this.toastService.error("You are not authorized!", "", {
          timeOut: 3000,
          positionClass: "toast-top-center",
        });
        this.router.navigate(["/users/profile"]);
      }
    } else {
      this.router.navigate(["/account/login"]);
      return false;
    }
  }
}
